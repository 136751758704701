script<template>
  <div>
    <div class="columns is-centered pt-5 px-5">
      <div class="column is-half ">
        <h1 class="form-title">Nuevo paciente</h1>
      </div>
    </div>
    <div class="columns is-centered pt-5 px-5">
      <div class="column is-half">
        <b-field
          label="Nombre"
          :message="[
            {
              'El nombre es obligatorio.':
                !$v.patientName.required && $v.patientName.$error,
            },
            {
              'La cantidad máxima de caracteres son 150.': !$v.patientName
                .maxLength,
            },
            {
              'El nombre no debe tener caracteres especiales ni números.': !$v
                .patientName.nameRegex,
            },
          ]"
          :type="$v.patientName.$error ? 'is-danger' : 'is-dark'"
        >
          <b-input
            type="text"
            v-model="patientName"
            :class="$v.patientName.$error ? 'error-input' : ''"
            @input="$v.patientName.$touch()"
            @blur="$v.patientName.$touch()"
            rounded
          >
          </b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-centered pt-3 px-5">
      <div class="column is-half">
        <b-field
          label="Número de documento"
          :message="[
            {
              'El documento es obligatorio.':
                !$v.newDoument.required && $v.newDoument.$error,
            },
            {
              'La cantidad máxima de caracteres son 12.': !$v.newDoument
                .maxLength,
            },
            {
              'La cantidad minima de caracteres son 3.': !$v.newDoument
                .minLength,
            },
            {
              'El documento debe ser numerico.': !$v.newDoument.numeric,
            },
          ]"
          :type="$v.newDoument.$error ? 'is-danger' : 'is-dark'"
        >
          <b-input
            type="text"
            v-model="newDoument"
            @input="$v.newDoument.$touch()"
            @blur="$v.newDoument.$touch()"
            :disabled="editMode"
            rounded
          >
          </b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-half pt-5 px-5">
        <b-field
          label="Tipo de documento"
          :message="[
            {
              'El tipo de documento es obligatorio.':
                !$v.documentTypeId.required && $v.documentTypeId.$error,
            },
          ]"
          :type="$v.documentTypeId.$error ? 'is-danger' : 'is-dark'"
        >
          <b-select
            placeholder="Seleccione"
            v-model="documentTypeId"
            rounded
            expanded
            @input="$v.documentTypeId.$touch()"
            @blur="$v.documentTypeId.$touch()"
          >
            <option value>Seleccione</option>
            <option
              v-for="document in documentTypes"
              :key="document.id"
              :value="document.id"
              >{{ document.name }}</option
            >
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="buttons is-centered">
      <b-button
        class="clear-button"
        icon-right="block-helper"
        @click="cancelRegister"
        >Cancelar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  helpers,
  numeric,
} from "vuelidate/lib/validators";

const nameRegex = helpers.regex("alpha", /^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1., ]*$/);

export default {
  data() {
    return {
      newDoument: "",
      patientName: "",
      documentTypeId: null,
      documentTypes: [],
      editMode: false,
      patientId: null,
    };
  },
  async created() {
    if (this.$route.params.patient) {
      this.editMode = true;
      this.patientId = this.$route.params.patient.id;
      this.newDoument = this.$route.params.patient.document;
      this.patientName = this.$route.params.patient.name;
      this.documentTypeId = this.$route.params.patient.document_id;
    }
    await this.getDocumentTypes();
  },
  methods: {
    async getDocumentTypes() {
      try {
        this.changeLoaderStatus(true);

        const response = await this.$patientService.getDocumentTypes();

        this.documentTypes = response.data;
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    async savePatient() {
      if (this.editMode == true) {
        await this.updatePatient();
      } else {
        await this.createPatient();
      }
    },
    async createPatient() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          name: this.patientName,
          document: this.newDoument,
          document_type_id: parseInt(this.documentTypeId),
        };
        const response = await this.$patientService.savePatient(data);

        this.$router.push({ name: "patients" }).then(() => {
          this.showToast(
            `El paciente ${response.data.name} ha sido creado exitoasamente.`,
            "is-success",
            3000
          );
        });
      } catch (error) {
        if (error.response.data.message == "DOCUMENTO_EN_USO") {
          this.showToast(
            `Este numero de documento ya existe se encuentra registrado en la plataforma.`,
            "is-danger",
            3000
          );
        }
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    async updatePatient() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          name: this.patientName,
          document: this.newDoument,
          document_type_id: parseInt(this.documentTypeId),
        };
        const response = await this.$patientService.updatePatient(
          this.patientId,
          data
        );

        this.$router.push({ name: "patients" }).then(() => {
          this.showToast(
            `El paciente ${response.data.name} ha sido creado exitoasamente.`,
            "is-success",
            3000
          );
        });
      } catch (error) {
        if (error.response.data.message == "DOCUMENTO_EN_USO") {
          this.showToast(
            `Este numero de documento ya existe se encuentra registrado en la plataforma.`,
            "is-danger",
            3000
          );
        }
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.savePatient();
      }
    },
    cancelRegister() {
      this.$router.push({ name: "patients" });
    },
  },
  validations: {
    patientName: {
      required,
      nameRegex,
      maxLength: maxLength(150),
    },
    newDoument: {
      required,
      numeric,
      minLength: minLength(3),
      maxLength: maxLength(12),
    },
    documentTypeId: {
      required,
    },
  },
};
</script>

<style scoped>
.form-title {
  font-size: 31px;
  font-weight: bold;
}

.form-container {
  box-shadow: -5px 13px 16px -4px rgba(0, 0, 0, 0.78);
}

.error-input {
  color: red;
  border-color: var(--dark-red-color);
}
</style>
