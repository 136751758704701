var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"columns is-centered pt-5 px-5"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":"Nombre","message":[
          {
            'El nombre es obligatorio.':
              !_vm.$v.patientName.required && _vm.$v.patientName.$error,
          },
          {
            'La cantidad máxima de caracteres son 150.': !_vm.$v.patientName
              .maxLength,
          },
          {
            'El nombre no debe tener caracteres especiales ni números.': !_vm.$v
              .patientName.nameRegex,
          } ],"type":_vm.$v.patientName.$error ? 'is-danger' : 'is-dark'}},[_c('b-input',{class:_vm.$v.patientName.$error ? 'error-input' : '',attrs:{"type":"text","rounded":""},on:{"input":function($event){return _vm.$v.patientName.$touch()},"blur":function($event){return _vm.$v.patientName.$touch()}},model:{value:(_vm.patientName),callback:function ($$v) {_vm.patientName=$$v},expression:"patientName"}})],1)],1)]),_c('div',{staticClass:"columns is-centered pt-3 px-5"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":"Número de documento","message":[
          {
            'El documento es obligatorio.':
              !_vm.$v.newDoument.required && _vm.$v.newDoument.$error,
          },
          {
            'La cantidad máxima de caracteres son 12.': !_vm.$v.newDoument
              .maxLength,
          },
          {
            'La cantidad minima de caracteres son 3.': !_vm.$v.newDoument
              .minLength,
          },
          {
            'El documento debe ser numerico.': !_vm.$v.newDoument.numeric,
          } ],"type":_vm.$v.newDoument.$error ? 'is-danger' : 'is-dark'}},[_c('b-input',{attrs:{"type":"text","disabled":_vm.editMode,"rounded":""},on:{"input":function($event){return _vm.$v.newDoument.$touch()},"blur":function($event){return _vm.$v.newDoument.$touch()}},model:{value:(_vm.newDoument),callback:function ($$v) {_vm.newDoument=$$v},expression:"newDoument"}})],1)],1)]),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-half pt-5 px-5"},[_c('b-field',{attrs:{"label":"Tipo de documento","message":[
          {
            'El tipo de documento es obligatorio.':
              !_vm.$v.documentTypeId.required && _vm.$v.documentTypeId.$error,
          } ],"type":_vm.$v.documentTypeId.$error ? 'is-danger' : 'is-dark'}},[_c('b-select',{attrs:{"placeholder":"Seleccione","rounded":"","expanded":""},on:{"input":function($event){return _vm.$v.documentTypeId.$touch()},"blur":function($event){return _vm.$v.documentTypeId.$touch()}},model:{value:(_vm.documentTypeId),callback:function ($$v) {_vm.documentTypeId=$$v},expression:"documentTypeId"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione")]),_vm._l((_vm.documentTypes),function(document){return _c('option',{key:document.id,domProps:{"value":document.id}},[_vm._v(_vm._s(document.name))])})],2)],1)],1)]),_c('div',{staticClass:"buttons is-centered"},[_c('b-button',{staticClass:"clear-button",attrs:{"icon-right":"block-helper"},on:{"click":_vm.cancelRegister}},[_vm._v("Cancelar")]),_c('b-button',{staticClass:"save-button",attrs:{"icon-right":"check-bold"},on:{"click":_vm.validate}},[_vm._v("Guardar")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-centered pt-5 px-5"},[_c('div',{staticClass:"column is-half "},[_c('h1',{staticClass:"form-title"},[_vm._v("Nuevo paciente")])])])}]

export { render, staticRenderFns }